import { Decimal } from 'decimal.js';
import { validate } from 'bitcoin-address-validation';

export function getMaxAllowable({ symbol, gas, amount, decimalPrecision }) {
  switch (symbol) {
    case 'ETH': {
      const ethAmt = Decimal(amount);
      const ethGas = Decimal(gas);
      return ethAmt.sub(ethGas).toFixed(decimalPrecision);
    }
    default:
      return Decimal.toFixed(decimalPrecision);
  }
}

export function getMaxCoinAndFiat({ coin, ethCoin, gas, precision = 8, isCore }) {
  const symbol = coin.coinSymbol;
  const useSameGas = symbol === 'ETH' || symbol.includes('-P') || symbol === 'BTC';
  const coinAmt = Decimal(coin.coinAmt);
  const gasAmt = Decimal(gas?.coin || 0);
  const maxCoin = useSameGas
    ? coinAmt.minus(gasAmt).toFixed(precision)
    : coinAmt.toFixed(precision);

  const maxFiat = coin?.priceUsd
    ? toFiat({
        amount: maxCoin,
        conversion: coin.priceUsd,
        precision: 2,
      })
    : null;

  const { error, message } = getCryptoSendError({
    coin,
    ethCoin,
    amount: maxCoin,
    gas: gasAmt,
    isCore,
  });

  return { maxCoin, maxFiat, error, message };
}

export function truncateWithoutRounding({ amount, precision }) {
  if (!amount) return '';
  const amountString = amount.toString();
  const [integers, decimals] = amountString.split('.');
  if (decimals) {
    const truncatedDecimals =
      decimals.length > precision ? decimals.substring(0, precision) : decimals;
    return `${integers}.${truncatedDecimals}`;
  } else {
    return `${integers}`;
  }
}

export function toCrypto({ conversion, amount, precision }) {
  return Decimal.div(amount, conversion).toFixed(precision);
}

export function toFiat({ conversion, amount, precision }) {
  return Decimal.mul(amount, conversion).toFixed(precision);
}

export function getCryptoSendError({ coin, ethCoin, amount, gas, isCore }) {
  let error = false;
  let message = '';

  if (coin.useEthGas) {
    const ethLow = Decimal(ethCoin.coinAmt).lessThan(gas);
    if (ethLow) {
      message = `This coin requires ETH to pay the gas fee. You don't have enough ETH in your wallet ${
        isCore ? '2.0' : '1.0'
      }`;
      error = true;
    }
    const amountHigh = Decimal(amount).greaterThan(coin.coinAmt);
    if (amountHigh) {
      message = 'The amount you are trying to transfer is larger than your balance.';
      error = true;
    }
    const amountLow = Decimal(amount).lessThanOrEqualTo(0);
    if (amountLow) {
      message = 'Your balance is insufficient to make a transfer.';
      error = true;
    }
  } else {
    const amountHigh = Decimal.add(amount, gas).greaterThan(coin.coinAmt);
    if (amountHigh) {
      message = 'Amount entered exceeds available balance with gas fee.';
      error = true;
    }
    const amountLow = Decimal(amount).lessThanOrEqualTo(0);
    if (amountLow) {
      message = 'Your transfer amount needs to be greater than 0.';
      error = true;
    }
  }
  return { error, message };
}

export function getCryptoAddressError({ coin, address }) {
  let error = false;
  let message = '';
  const btcNetwork =
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' ? 'mainnet' : 'testnet';

  if (address === coin.walletId) {
    error = true;
    message = 'You cannot send funds to yourself.';
  } else {
    const ethRegex = /^0x[a-fA-F0-9]{40}$/;
    const isValidAddress =
      coin.coinSymbol === 'BTC' ? validate(address, btcNetwork) : ethRegex.test(address);
    if (!isValidAddress) {
      error = true;
      message = 'Incorrect wallet address format, please try again';
    }
  }

  return { error, message };
}
