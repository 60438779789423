import { useWalletContext } from '@/providers/WalletProvider';

export const useWallet = () => {
  const {
    brandCoins,
    btcMigRequired,
    chainInfo,
    coreGetWalletItems,
    coreWalletCoins,
    coreWalletCoinState,
    coreWalletError,
    erc20Configurations,
    findCoin,
    getWalletItems,
    handleGetActiveWallets,
    hasCoreWallet,
    hasNoWallet,
    hasDuplicateWallets,
    walletCoins,
    walletCoinState,
    walletError,
  } = useWalletContext();

  return {
    brandCoins,
    btcMigRequired,
    chainInfo,
    coreGetWalletItems,
    coreHasError: coreWalletError,
    coreWalletCoins,
    coreWalletCoinState,
    erc20Configurations,
    findCoin,
    getWalletItems,
    handleGetActiveWallets,
    hasCoreWallet,
    hasDuplicateWallets,
    hasNoWallet,
    hasError: walletError,
    walletCoins,
    walletCoinState,
  };
};
