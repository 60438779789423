// Utils
import { brand } from '@/brand/brand';
import {
  getAcceptedERC20,
  getChainInfo,
  getUserWalletItem,
} from '@/resources/wallet-service.resource';
import { getUserTokenBalance } from '@/resources/chain-ledger-service.resource';
// Icons
import BTC from '@/icons/logos/bitcoin.svg';
import ETH from '@/icons/logos/ethereum.svg';
import USDC from '@/icons/logos/usdc_icon.png';

// See assets/icons/logos for brand icons available to Core - update as necessary, key should match coin symbol
const COIN_ICONS = {
  BTC: BTC.src,
  ETH: ETH.src,
  USDC: USDC.src,
  WBTC: BTC.src,
  WETH: ETH.src,
  [brand.coin]: brand.tokenIcon,
  [`${brand.coin}-P`]: brand.tokenIconL2,
};

export const extractCoinData = (coin = null) => {
  if (!coin) return;
  return {
    useEthGas: coin.coinData.useEthGas,
    priceUsd: coin.coinData.priceUsd,
    coinSymbol: coin.coinData.symbol,
    coinAmt: coin.coinData.balance,
    coinLogo: coin.coinData.icon,
    coinName: coin.coinData.name,
    hasError: !!coin.coinData.hasError,
    usd: coin.coinData.balanceUsd || null,
    walletId: coin.coinData.address,
    valid: coin.coinData.canSendFunds,
  };
};

export const buildCoinDataObj = (coin) => ({
  coinData: {
    address: coin.walletId,
    balance: coin.coinAmt,
    balanceUsd: coin.coinSymbol === 'ETH' || coin.coinSymbol === 'BTC' ? coin.usd : null,
    canSendFunds: coin.valid,
    hasError: coin.hasError,
    icon: coin.coinLogo,
    name: coin.coinName,
    symbol: coin.coinSymbol,
  },
});

export const nullCoinDataObj = (symbol) => ({
  coinData: {
    address: null,
    balance: '0.0',
    balanceUsd: '0.0',
    canSendFunds: false,
    icon: COIN_ICONS[symbol],
    name: symbol,
    symbol: symbol,
    hasError: true,
  },
});

export async function handleGetChainInfo(fetcher, retryCount = 0) {
  try {
    return await fetcher(getChainInfo());
  } catch (error) {
    if (retryCount < 3) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return handleGetChainInfo(fetcher, retryCount + 1);
    } else {
      return null;
    }
  }
}

export async function handleGetWallet(
  setLoading = false,
  isLegacy = true,
  fetcher,
  dispatch,
  findCoin,
  setError,
  useBrandCoin
) {
  // Reset errors
  setError('reset');

  setLoading &&
    dispatch(
      !isLegacy
        ? { type: 'SET_CORE_WALLET_LOADING', payload: true }
        : { type: 'SET_WALLET_LOADING', payload: true }
    );

  const erc20Coins = await fetcher(getAcceptedERC20()).catch(() => []);
  const erc20Symbols = erc20Coins.map((coin) => coin.symbol);
  const symbols = [...brand.pages.dashboard.walletCoins, ...erc20Symbols];

  const coins = await Promise.all(
    symbols.map(async (symbol) => {
      try {
        let coinData = await fetcher(getUserWalletItem({ symbol, isLegacy }));

        const useEth = symbol !== 'BTC';

        coinData = {
          ...coinData,
          useEthGas: useEth,
          icon: COIN_ICONS[symbol],
        };
        if (symbol === brand.coin) {
          coinData = {
            ...coinData,
            name: `${brand.brandChainName} ERC-20`,
          };
        }

        return { coinData };
      } catch (error) {
        const setCoinError = () => {
          setError({ coin: true });
          const coinState = findCoin(symbol);
          return coinState ? buildCoinDataObj(coinState) : nullCoinDataObj(symbol);
        };

        // Handle request timeout error for axios
        if (error.message.includes('timeout')) {
          if (symbol === 'ETH') {
            setError({ server: true });
            return null;
          }
          return setCoinError();
        }

        if (error?.response?.status === 404) return setCoinError();

        // Handle other errors 500, 504, etc.
        if (symbol === 'ETH') {
          setError({ server: true });
          return null;
        }
        return setCoinError();
      }
    })
  );

  const ethCoin = coins?.find((coin) => coin?.coinData?.symbol === 'ETH')?.coinData;

  const coinsArr = coins;

  const brandL2Coin = {
    ...ethCoin,
    useEthGas: false,
    balance: '0.0',
    balanceUsd: '0.0',
    name: `${brand.brandChainName} Rewards`,
    symbol: `${brand.coin}-P`,
    icon: COIN_ICONS[`${brand.coin}-P`],
  };

  if (brand.pages.dashboard.useBrandCoin && ethCoin?.address) {
    const brandCoinRPC = await fetcher(getUserTokenBalance({ walletId: ethCoin.address }))
      .then((result) => ({
        coinData: {
          ...brandL2Coin,
          balance: result.amount,
          priceUsd: '0',
        },
      }))
      .catch(() => {
        setError({ coin: true });
        return {
          coinData: {
            ...brandL2Coin,
            hasError: true,
          },
        };
      });
    coinsArr.unshift(brandCoinRPC);
  } else {
    useBrandCoin && coinsArr.unshift({ coinData: brandL2Coin });
  }

  const walletItemsArr = coinsArr.map(extractCoinData);
  // only update the wallet if we have items to display
  if (walletItemsArr.length)
    dispatch(
      !isLegacy
        ? { type: 'SET_CORE_WALLET_STATE', payload: walletItemsArr }
        : { type: 'SET_WALLET_STATE', payload: walletItemsArr }
    );

  dispatch(
    !isLegacy
      ? { type: 'SET_CORE_WALLET_LOADING', payload: false }
      : { type: 'SET_WALLET_LOADING', payload: false }
  );
  dispatch({ type: 'COMPARE_WALLETS' });

  return walletItemsArr;
}
